import $ from 'jquery';
import Swiper from 'swiper';

$(() => {

  if (document.getElementById('homeslider')) {

    let navigation = {
      prevEl: document.querySelector('#homeslider-cover .swiper-button-prev'),
      nextEl: document.querySelector('#homeslider-cover .swiper-button-next'),
    };

    let pagination = document.querySelector('#homeslider-content .swiper-pagination');
    let autoplay = document.querySelector('#homeslider meta[name="autoplay"]').getAttribute('content');
    let repeat = document.querySelector('#homeslider meta[name="repeat"]').getAttribute('content');
    let pause = document.querySelector('#homeslider meta[name="pause"]').getAttribute('content');

    let HomeSliderContent,
        HomeSliderCover;

    HomeSliderContent = new Swiper('#homeslider-content', {
      effect: 'fade',
      loop: repeat === 'true',
      autoplay: {
        delay: parseInt(autoplay, 10)
      },
      pagination: {
        el: pagination,
        type: 'bullets',
        clickable: true,
      },
    });

    HomeSliderCover = new Swiper('#homeslider-cover', {
      navigation: navigation,
      loop: true,
    });

    HomeSliderContent.controller.control = HomeSliderCover;
    HomeSliderCover.controller.control = HomeSliderContent;

    if (pause === 'hover') {
      document.getElementById('homeslider').addEventListener('mouseover', () => {
        HomeSliderContent.autoplay.stop();
      });

      document.getElementById('homeslider').addEventListener('mouseout', () => {
        HomeSliderContent.autoplay.start();
      });
    }

  }

});
