import debounce from 'lodash/debounce';

import {
    withEl,
    ready,
} from '../common/utils';

import {
    emit,
    listen,
} from '../common/events'; 

 
export class sidebar {
    static init () {
        let hasNotInitializedEl = false
        withEl('[data-sidebar]:not(.js-sidebar-initialized)', el => {
            el.addEventListener('click', ({ target: el }) => {
                if (!el.hasAttribute('data-sidebar')) {
                    return;
                }

                emit('sidebar:toggle', { el, force: false });
            });

            el.classList.add('js-sidebar-initialized');

            hasNotInitializedEl = true;
        });

        withEl('[data-sidebar-close]:not(.js-sidebar-initialized)', el => {
            el.addEventListener('click', event => {
                event.preventDefault();

                const parent = event.currentTarget.closest('[data-sidebar]');
                emit('sidebar:toggle', { el: parent, force: false });
            });

            el.classList.add('js-sidebar-initialized');
        });

        withEl('[data-sidebar-trigger]:not(.js-sidebar-initialized)', el => {
            el.addEventListener('click', event => {
                event.preventDefault();

                withEl(`[data-sidebar=${event.currentTarget.dataset.sidebarTrigger}]`, (sidebar) => {
                    emit('sidebar:toggle', { el: sidebar, source: el });
                });
            });

            el.classList.add('js-sidebar-initialized');
        });

        if (hasNotInitializedEl) {
            emit('sidebar:resize');
        }
    }
}

listen('sidebar:toggle', ({ el, force = true }) => {
    document.body.classList.toggle('sidebar--opened', force);
    el.classList.toggle('sidebar--opened', force);

    if (force) {
        emit('sidebar:resize', { sidebar: el.dataset.sidebar });
    }
});

listen('sidebar:resize', (data = {}) => {
    let selector = '[data-sidebar]';
    if (data.sidebar != undefined) {
        selector = `[data-sidebar="${data.sidebar}"]`;
    }

    withEl(selector, (el) => {
        let scrollTop = 0;

        const scrollEl = el.querySelector('.sidebar__inner');
        if (scrollEl) {
            scrollTop = scrollEl.scrollTop;
        }

        // Force reset to reflow height calculation
        el.classList.remove('sidebar--scrollable');
        el.classList.toggle('sidebar--scrollable', el.firstElementChild.offsetHeight > window.innerHeight);

        if (scrollEl) {
            scrollEl.scrollTop = scrollTop;
        }
    }, { ready: true });
});

window.addEventListener('resize', debounce(() => {
    emit('sidebar:resize');
}, 200));

ready(() => {
    sidebar.init();
    
    prestashop.on('updatedProduct', function (event) {    
        sidebar.init();
    });

});
