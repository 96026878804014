import $ from 'jquery';
import Swiper from 'swiper';

$(document).ready(() => {
  createProductSpin();
  loadUrlOptions();

  if(typeof gtmProductsOnload == 'function') {
    gtmProductsOnload();
  }

  var modalSlider;
  var mainSlider;

  function initProductTabs() {
    if (window.innerWidth < 768) {
      $('.js-product-tabs-mobile').append($('.js-product-tabs-content').detach());
    } else {
      $('.js-product-tabs-desktop').append($('.js-product-tabs-content').detach());
    }
  }

  function initSlider () {
    mainSlider = new Swiper('.js-product-images-main', {
      slidesPerView: 1,
      initialSlide: $('.js-product-images-main [data-cover]').index(),
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      scrollbar: {
        el: document.querySelector('.product-images__main-wrapper .swiper-scrollbar'),
        draggable: true,
      },
      thumbs: {
        swiper: {
          el: '.js-product-images-nav',
          slidesPerView: 3,
          direction: 'vertical',
          navigation: {
            prevEl: document.querySelector('.product-images__list-wrapper .swiper-button-prev'),
            nextEl: document.querySelector('.product-images__list-wrapper .swiper-button-next'),
          },
        }
      },
    });
  }

  function initModal () {
    $('#zoom-modal').on('shown.bs.modal', function () {
      modalSlider = new Swiper('.js-product-images-modal', {
        initialSlide: mainSlider.activeIndex,
        slidesPerView: 1,
        loop: true,
        effect: 'fade',
        keyboard: true,
        fadeEffect: {
          crossFade: true,
        },
        navigation: {
          prevEl: document.querySelector('.product-images-modal-wrapper .swiper-button-prev'),
          nextEl: document.querySelector('.product-images-modal-wrapper .swiper-button-next'),
        },
      });
    });

    $('#zoom-modal').on('hidden.bs.modal', function () {
      modalSlider.destroy();
    });
  }

  initSlider();
  initModal();
  initProductTabs();

  $(window).on('resize', initProductTabs);

  prestashop.on('updatedProduct', function (event) {
    if (event && event.product_minimal_quantity) {
      const minimalProductQuantity = parseInt(event.product_minimal_quantity, 10);
      const quantityInputSelector = '.js-quantity-wanted';
      let quantityInput = $(quantityInputSelector);

      quantityInput.trigger('touchspin.updatesettings', {min: minimalProductQuantity});
    }

    initSlider();
    initModal();
    createProductSpin();

    $($('.product-tabs .nav-link.active').attr('href')).addClass('active').removeClass('fade');
  });

  $('#product').on('click', '.product-images .js-zoom-modal-link', function (e) {
    e.preventDefault();

    $('#zoom-modal').modal('show');
  });

  function createProductSpin() {
    const $quantityInput = $('.js-quantity-wanted');

    $quantityInput.TouchSpin({
      buttondown_class: 'btn btn-touchspin btn-touchspin--prepend js-touchspin',
      buttonup_class: 'btn btn-touchspin btn-touchspin--append js-touchspin',
      min: parseInt($quantityInput.attr('min'), 10),
      max: Infinity
    });

    var quantity = $quantityInput.val();
    $quantityInput.on('keyup change', function (event) {
      const newQuantity = $(this).val();
      if (newQuantity !== quantity) {
        quantity = newQuantity;
        let $productRefresh = $('.product-refresh');
        $(event.currentTarget).trigger('touchspin.stopspin');
        $productRefresh.trigger('click', {eventType: 'updatedProductQuantity'});
      }
      event.preventDefault();

      return false;
    });
  }

  function loadUrlOptions() {
    var hash = window.location.hash.substr(1);
    if (!hash) return;
    var attributes = hash.split("/");
    if (!attributes || attributes.length <= 0) return;

    for (let i = 1; i < attributes.length; i++) {
      if (attributes[i].trim() === "") continue;
      var splitAttribute = attributes[i].split("-");
      if (!splitAttribute || splitAttribute.length <= 0) continue;
      var id = splitAttribute[0];
      var htmlAttributesElements = $(document).find(".product-variants > .product-variants-item").find('[value="' + id + '"]').toArray();
      if (!htmlAttributesElements || htmlAttributesElements.length <= 0) continue;
      switch ($(htmlAttributesElements[0]).prop("tagName")) {
        case "INPUT":
          var attribValue = $(htmlAttributesElements[0]).attr("data-product-attribute");
          $(htmlAttributesElements[0]).attr("checked", "checked");
          break;
        case "OPTION":
          var attribValue = $(htmlAttributesElements[0]).attr("data-product-attribute");
          $(htmlAttributesElements[0]).attr("selected", true);
          break;
      }
    }

    setTimeout(function() {
      $("input[name$='refresh']").click();
    }, 50);
  }
});
