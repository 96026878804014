import $ from 'jquery';

export function linkDecrypt() {
  var $link;

  $('[data-href]').each(function() {
    $link = $('<a />')
      .attr('href', hex2bin($(this).data('href')))
      .attr('target', $(this).data('target'))
      .attr('rel', $(this).data('rel'))
      .attr('title', $(this).data('title'))
      .attr('class', $(this).attr('class'))
      .attr('style', $(this).attr('style'))
      .attr('onclick', $(this).attr('onclick'))
      .attr('aria-label', $(this).attr('aria-label'))
      .addClass('js-link-replaced')
      .append(this.innerHTML)
    ;

    $(this).replaceWith($link);
  });
}

function hexdec (hex_string) {
  hex_string = (hex_string + '').replace(/[^a-f0-9]/gi, '');
  return parseInt(hex_string, 16);
}

function chr(AsciiNum) {
  return String.fromCharCode(AsciiNum);
}

function hex2bin(hex){
  var r = '',
      a = 0;

  for (a = 0; a < hex.length; a = a + 2) {
      r = r + chr(hexdec(hex.slice(a, a + 2)));
  }

  return r;
}
