import Cookie from 'js-cookie';

export function getCssVar(varName) {
    return getComputedStyle(document.documentElement).getPropertyValue(varName);
}

export function getBreakpoint(name) {
    const val = getCssVar(`--breakpoint-${name}`).replace('px', '');
    return parseInt(val, 10);
}

export function ready(func) {
    if (document.readyState === 'interactive' || document.readyState === 'complete') {
        return func();
    }

    document.addEventListener('DOMContentLoaded', func);
}

export function load(func) {
    if (document.readyState === 'complete') {
        return func();
    }

    window.addEventListener('load', func);
}

export function getSiblings (el) {
    return [...el.parentElement.children].filter(child => child != el);
}

export function withEl(selector, func, options = {}) {
    let roots = [document];

    if (options.root) {
        roots = (typeof options.root == 'string' ? Array.from(document.querySelectorAll(options.root)) : [options.root]);
    }

    const els = typeof selector == 'object' ? [selector] : roots.flatMap(root => Array.from(root.querySelectorAll(selector)));

    if (options.ready !== true) {
        els.forEach(func);
        return;
    }

    return ready(() => {
        els.forEach(func);
    });
}

export function setListener(selector, event, func, options = {}) {
    withEl(selector, el => {
        el.addEventListener(event, (e) => {
            if (options.prevent) {
                e.preventDefault();
            }

            func(e);
        }, options)
    }, options);
}

export function whenIntersecting(selector, func, options = {}) {
    const observer = new IntersectionObserver(entries => {
        entries.forEach((entry) => {
            if (! entry.isIntersecting) {
                return;
            }

            func(entry, observer);
        }, options);
    });

    withEl(selector, el => {
        observer.observe(el);
    }, options);

    return observer;
}

export function mouseEventOffset(event, target) {
    target = target || event.currentTarget || event.srcElement;

    const cx = event.clientX || 0;
    const cy = event.clientY || 0;
    const rect = getBoundingClientOffset(target);

    return {
        offsetX: cx - rect.left,
        offsetY: cy - rect.top
    };
}

export function formatPrice(price) {
    return new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'EUR',
    }).format(price);
}

function getBoundingClientOffset(el) {
    if (el === window || el === document || el === document.body) {
        return { left: 0, top: 0 };
    } else {
        return el.getBoundingClientRect()
    }
}

export function strip (html) {
    const el = document.createElement('div');
    el.innerHTML = html;

    return el.textContent || el.innerText || '';
}

export class Logger {
    DEBUG_KEY = 'FTO_DEBUG';

    constructor (name, level = "info") {
        this.LOGGER_KEY = `${this.DEBUG_KEY}_${name.toUpperCase()}`;

        this.name  = name;
        this.level = level;
    }

    get isLoggable () {
        return [
            Cookie.get(this.LOGGER_KEY),
            Cookie.get(this.DEBUG_KEY),
        ].indexOf('1') != -1;
    }

    log (message, ...args) {
        if (!this.isLoggable) return;
        window.console[this.level](`[${this.name}] ${message}`, ...args);
    }
}
