import 'bootstrap/dist/js/bootstrap.min';
import 'bootstrap-touchspin';

import 'mutationobserver-shim';

import './responsive';
import './checkout';
import './customer';
import './listing';
import './product';
import './cart';

import Form from './components/form';
import './components/block-cart';
import './components/sidebar';

import prestashop from 'prestashop';
import EventEmitter from 'events';

import $ from 'jquery';
import Swiper from 'swiper';

import './modules/homeslider';
import './modules/fractoslideblock';
import './modules/fractomenu';

import { linkDecrypt } from './components/links';

// "inherit" EventEmitter
for (var i in EventEmitter.prototype) {
  prestashop[i] = EventEmitter.prototype[i];
}

if ('NodeList' in window && !NodeList.prototype.forEach) {
  console.info('polyfill for IE11'); 
  NodeList.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window;
    for (var i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}

const DOMobserver = new MutationObserver(() => {
  linkDecrypt();
});

DOMobserver.observe(document.body, { childList: true, subtree: true });

$(document).ready(() => {
  linkDecrypt();

  const form = new Form();

  document.querySelectorAll('.js-product-slider').forEach((el) => {
    let navigation = {
      prevEl: el.parentNode.querySelector('.swiper-button-prev'),
      nextEl: el.parentNode.querySelector('.swiper-button-next'),
    };

    // xs: 0,
    // sm: 576px,
    // md: 768px,
    // lg: 992px,
    // xl: 1200px,
    // ml: 1600px,

    var config = {
      speed: 750,
      slidesPerGroup: 1,
      slidesPerView: 1,
      centerInsufficientSlides: true,
      breakpointsInverse: true,
      breakpoints: {
        576: {
          slidesPerGroup: 2,
          slidesPerView: 2,
          navigation: navigation,
        },
        992: {
          slidesPerGroup: 3,
          slidesPerView: 3,
          navigation: navigation,
        },
        1200: {
          slidesPerGroup: 4,
          slidesPerView: 4,
          navigation: navigation,
        },
      }
    };

    if (el.getAttribute('autoplay')) {
      config.autoplay = { delay: 5000 };
    }

    new Swiper(el, config);
  });

  $('#discover-modal').on('shown.bs.modal', function () {
    $(this).find('.js-discover-video').get(0).play();
  });

  $('#discover-modal').on('hide.bs.modal', function () {
    $(this).find('.js-discover-video').get(0).pause();
  });

  $('#discover-modal').on('hidden.bs.modal', function () {
    $(this).find('.js-discover-video').get(0).pause();
    $(this).find('.js-discover-video').get(0).currentTime = 0;
  });
});
