import $ from 'jquery';
import Swiper from 'swiper';

$(() => {

  if (document.getElementById('fractoslideblock')) {

    let navigation = {
      prevEl: document.querySelector('#fractoslideblock-cover .swiper-button-prev'),
      nextEl: document.querySelector('#fractoslideblock-cover .swiper-button-next'),
    };

    let pagination = document.querySelector('#fractoslideblock-content .swiper-pagination');

    let HomeSliderContent,
        HomeSliderCover;

    HomeSliderContent = new Swiper('#fractoslideblock-content', {
      effect: 'fade',
      loop: true,
      pagination: {
        el: pagination,
        type: 'bullets',
        clickable: true,
      },
    });

    HomeSliderCover = new Swiper('#fractoslideblock-cover', {
      navigation: navigation,
      loop: true,
    });

    HomeSliderContent.controller.control = HomeSliderCover;
    HomeSliderCover.controller.control = HomeSliderContent;
  }

  // Sliders pour page CMS
  if (document.getElementById('fractoslideblock-cms-1')) {

    let navigation = {
      prevEl: document.querySelector('#fractoslideblock-cms-1-cover .swiper-button-prev'),
      nextEl: document.querySelector('#fractoslideblock-cms-1-cover .swiper-button-next'),
    };

    let pagination = document.querySelector('#fractoslideblock-cms-1-content .swiper-pagination');

    let CmsSliderContent,
        CmsSliderCover;

    CmsSliderContent = new Swiper('#fractoslideblock-cms-1-content', {
      effect: 'fade',
      autoplay: true,
      loop: true,
      pagination: {
        el: pagination,
        type: 'bullets',
        clickable: true,
      },
    });

    CmsSliderCover = new Swiper('#fractoslideblock-cms-1-cover', {
      navigation: navigation,
      loop: true,
    });

    CmsSliderContent.controller.control = CmsSliderCover;
    CmsSliderCover.controller.control = CmsSliderContent;
  }

  if (document.getElementById('fractoslideblock-cms-2')) {

    let navigation = {
      prevEl: document.querySelector('#fractoslideblock-cms-2-cover .swiper-button-prev'),
      nextEl: document.querySelector('#fractoslideblock-cms-2-cover .swiper-button-next'),
    };

    let pagination = document.querySelector('#fractoslideblock-cms-2-content .swiper-pagination');

    let CmsSliderContent,
        CmsSliderCover;

    CmsSliderContent = new Swiper('#fractoslideblock-cms-2-content', {
      effect: 'fade',
      loop: true,
      autoplay: true,
      pagination: {
        el: pagination,
        type: 'bullets',
        clickable: true,
      },
    });

    CmsSliderCover = new Swiper('#fractoslideblock-cms-2-cover', {
      navigation: navigation,
      loop: true,
    });

    CmsSliderContent.controller.control = CmsSliderCover;
    CmsSliderCover.controller.control = CmsSliderContent;
  }

});
