import $ from 'jquery';
import prestashop from 'prestashop';

$(document).ready(() => {
  hoverMiniatiureColorImgEvents();

  $('#js-active-search-filters').insertAfter($('.categorytree'));

  $('input[type=checkbox]').each(function () {
      if(this.checked) {
        $(this).closest($('.facet__option-list')).addClass('toggle');
        $(this).closest($('.facet__title')).addClass('toggle');
      }
  });

  const parseNextFilter = function (event) {
    if (event.target.dataset.filter !== undefined) {
      return '?q=' +event.target.dataset.filter;
    }

    if ($(event.target).parent()[0].dataset.filter === undefined) {
      throw new Error('Can not parse search URL');
    }

    return '?q=' + $(event.target).parent()[0].dataset.filter;
  };
  const parseSearchUrl = function (event) {
    if (event.target.dataset.searchUrl !== undefined) {
      return event.target.dataset.searchUrl;
    }

    if ($(event.target).parent()[0].dataset.searchUrl === undefined) {
      throw new Error('Can not parse search URL');
    }

    return $(event.target).parent()[0].dataset.searchUrl;
  };

  if ($('.js-back-to-top').length > 0) {
    $(window).on('scroll', function() {
      if ($(window).scrollTop() > 0) {
        if ($('.js-back-to-top').hasClass('shown')) {
          return;
        }

        $('.js-back-to-top').addClass('shown');
        return;
      }

      $('.js-back-to-top').removeClass('shown');
    });

    $('.js-back-to-top').on('click', function() {
      window.scroll(0, 0);
      return false;
    });
  }

  $(document).on('click', '.facet__title-js', function() {
    $(this).toggleClass('toggle');
    $(this).siblings().toggleClass('toggle');
  });

  $('body').on('change', '#search_filters input[data-search-url]', function (event) {
    prestashop.emit('updateFacets', parseSearchUrl(event));
  });

  $('body').on('change', '#search_filters input[data-filter]', function (event) {
    var baseUrl = $('link[rel="canonical"]').attr('href');
    var url = baseUrl + parseNextFilter(event);
    prestashop.emit('updateFacets', url);
  });

  $('body').on('change', '#search_filters select', function (event) {
    prestashop.emit('updateFacets', $(event.target).val());
  });

  $('body').on('click', '.js-search-filters-clear-all', function (event) {
    prestashop.emit('updateFacets', parseSearchUrl(event));
  });

  $('body').on('click', '.js-search-link', function (event) {
    event.preventDefault();
    prestashop.emit('updateFacets', $(event.target).closest('a').get(0).href);
  });

  $('body').on('change', '#search_filters select', function (event) {
    const form = $(event.target).closest('form');
    prestashop.emit('updateFacets', '?' + form.serialize());
  });

  $('body').on('change', '.js-search-select', function (event) {
    event.preventDefault();
    prestashop.emit('updateFacets', $(event.target).val());
  });

  $('body').on('click', '.read-more', function () {
    let $parent = $(this).closest('.js-togglable-text-holder');

    $parent.find('.hide-text').show();
    $parent.find('.read-more, .dots').hide();
  });

  prestashop.on('updateProductList', (data) => {
    updateProductListDOM(data);
  });
});

function updateProductListDOM(data) {

  $('#search_filters').replaceWith(data.rendered_facets);
  if (typeof data.metas != 'undefined') {

    if (typeof data.metas.title != 'undefined') {
      $('.product-list__heading h1').html(data.metas.title);
    }

    if (typeof data.metas.description != 'undefined') {
      $('.product-list__description').html(data.metas.description);
    }

    if (typeof data.metas.block_top != 'undefined') {
      $('.fractoblock-top').html(data.metas.block_top);
    } else {
      $('.fractoblock-top').html('');
    }

    if (typeof data.metas.block_bottom != 'undefined') {
      $('.fractoblock-bottom').html(data.metas.block_bottom);
    } else {
      $('.fractoblock-bottom').html('');
    }

    if (typeof data.metas.breadcrumb_html != 'undefined') {
      $('.header-content .breadcrumb_container').replaceWith(data.metas.breadcrumb_html);
    }
  }
  $('.product-list__heading .listing-nb-products').html('<b>' + data.pagination.total_items + '</b>' + (data.pagination.total_items > 1 ? ' produits' : ' produit') );

  $('#js-active-search-filters').insertAfter($('.categorytree'));

  $('#js-active-search-filters').replaceWith(data.rendered_active_filters);

  $('input[type=checkbox]').each(function () {
      if(this.checked) {
        $(this).closest($('.facet__option-list')).addClass('toggle');
        $(this).closest($('.facet__title')).addClass('toggle');
      }
  });

  $('#js-product-list-top').replaceWith(data.rendered_products_top);
  $('#js-product-list').replaceWith(data.rendered_products);
  $('#js-product-list-bottom').replaceWith(data.rendered_products_bottom);
}

function hoverMiniatiureColorImgEvents() {
  $(document).on('mouseover', '.product-miniature__variants a.color', function() {
    if($(this).find('input.attribute-cover').length) {
      var coverImg = $(this).find('input.attribute-cover').val();
      $(this).parents('.product-miniature').find('.product-miniature__thumbnail-wrapper img').attr('src', coverImg);
    }
  });
} 
