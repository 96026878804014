import EventEmitter from 'events';
import Cookie from 'js-cookie';

const DEBUG = Cookie.get('FTO_DEBUG_EVENTS') == '1' || Cookie.get('FTO_DEBUG') == '1';

const ee = new EventEmitter();

// Note : progressively increase listener limit if needed
// Do not set a huge value or unlimited value (0) to keep control of what's going on
ee.setMaxListeners(30);

export function emit (event, args) {
    if (DEBUG) console.info(`[Event] Triggered Event "${event}"`, args);

    return ee.emit(event, args);
}

export function listen (event, callback) {
    return ee.on(event, callback);
}

export function listenAsync (event) {
    return new Promise((resolve, reject) => {
        // listen(event, resolve);
        listen(event, (data) => {
            resolve(data);
        });
    });
}

export function off (event, listener) {
    if (DEBUG) console.info(`[Event] Disable event ${event}.`);

    if (typeof listener == 'function') {
        return ee.off(event, listener);
    }

    return ee.removeAllListeners(event);
}

window.ee = {
    emit,
    listen,
    listenAsync,
    off,
};
